.home {
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #1a1826, #413b5e);
  padding: 20px;
  align-items: center; /* Centra horizontalmente */
  justify-content: center; /* Centra verticalmente */
  height: 100vh; /* Asegúrate de que ocupe toda la altura de la ventana */
}

.titleContainer {
  text-align: center; /* Centra el texto dentro del contenedor */
}

.home p {
  font-size: 2.5em;
  color: #f1f1f1;
}

.callToAction {
  border: 2px solid green;
  border-radius: 10px;
  padding: 10px 20px; /* Aumentar el espacio interior */
  margin-top: 20px;
  background-color: green; /* Color de fondo verde */
  color: white; /* Texto en negro */
  text-decoration: none; /* Sin subrayado */
  font-weight: bold; /* Texto en negrilla */
  transition: background-color 0.3s ease, color 0.3s ease; /* Suave transición */
}

.callToAction:hover {
  background-color: #00ff00; /* Cambia el color de fondo en hover */
  color: black; /* Texto en blanco cuando pasa el mouse */
  padding: 10px 20px; /* Mantener el tamaño del botón en hover */
  cursor: pointer;
}

.ctaContainer {
  padding-top: 40px;
  display: flex; /* Para centrar el botón */
  justify-content: center; /* Centra el botón horizontalmente */
}