.services {
  display: flex;
  padding-top: 100px;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(0deg, #1a1826, #413b5e);
}

.webImage {
  width: 298px;
  height: 298px;
  padding-bottom: 80px;
}

.services p {
  color: #f1f1f1;
  font-size: 1.5em;
  text-align: center;
  padding-bottom: 60px;
}

@media (min-width: 800px) {
  .services p {
    font-size: 2.5em;
  }
  .webImage {
    width: 596px;
    height: 386px;
  }
}
