.benefits {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(0deg, #1a1826, #413b5e);
  padding-bottom: 100px;
}

.benefits p {
  color: #f1f1f1;
  padding-top: 20px;
  font-size: 1.4em;
}

.benefitTitle {
  font-size: 2em;
  font-weight: 400;
  color: #f1f1f1;
}
