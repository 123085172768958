/* Estilos básicos del navbar */
.navBar {
  display: flex;
  height: 60px;
  width: 100%;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  background-color: #4a4564;
  padding: 0 20px; /* Asegura espacio a ambos lados del navbar */
  z-index: 1000;
}

/* Menú abierto en dispositivos móviles */
.navOpen {
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #4a4564;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 1001; /* Asegura que el menú siempre esté por encima */
}

/* Ocultar el icono del menú en pantallas grandes */
.hideOnDesktop {
  display: block;
}

@media (min-width: 768px) {
  .hideOnDesktop {
    display: none;
  }

  /* Alinear los elementos en fila y ajustar espaciado */
  .navList {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .navLink {
    padding: 0 10px;
    display: flex;
    align-items: center;
  }

  /* Asegurarse de que el último enlace no se pegue al borde derecho */
  .navList li:last-child {
    margin-right: 30px; /* Añadir más espacio al último elemento */
  }

  .navLink a {
    text-decoration: none;
    color: #f1f1f1;
  }

  .navLink a:visited {
    color: #f1f1f1;
  }

  .navLink:hover a {
    color: #00ffcc; /* Cambia el color al pasar el mouse */
  }
}

/* Clase para el enlace de Contact con fondo verde, texto negro y en negrilla */
.contactLink {
  border: 2px solid green;
  border-radius: 10px;
  padding: 5px 15px; /* Añadir más espacio lateral dentro del botón */
  color: white !important;
  background-color: green !important;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Cambiar el color del fondo y el texto al pasar el mouse */
.contactLink:hover {
  border-color: #00ff00;
  background-color: #00ff00 !important;
  color: black !important;
  font-weight: bold;
}

/* Menú en dispositivos móviles */
@media (max-width: 768px) {
  .navList {
    display: none;
    flex-direction: column;
    align-items: center;
    list-style: none;
  }

  .navOpen .navList {
    display: flex;
  }

  .navLink {
    margin-bottom: 20px;
    font-size: 24px;
  }

  .navLink a {
    color: white;
    text-decoration: none;
  }

  .navLink:hover a {
    color: #00ffcc;
  }

  /* Ajustar el botón Contact en móviles */
  .contactLink {
    padding: 10px 20px; /* Aumentar el padding en móviles */
    margin-right: 15px; /* Añadir un pequeño margen a la derecha */
  }
}

.logo {
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: #ffffff;
}

.logoImage {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.menuIcon {
  cursor: pointer;
  font-size: 25px;
  color: white;
  margin-right: 20px; /* Añadir margen al ícono para que no esté tan pegado */
}
